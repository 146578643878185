import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Box,
  Container,
  makeStyles,
  CircularProgress,
  Backdrop,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import Page from "../../../components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import CustomerInfos from "./CustomerInfos";
import TrainingProgramForm from "./TrainingProgramForm";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useLocation, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { setProgStatus } from "../../../korper-redux/actions";
import axios from "axios";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MenuIcon from "@material-ui/icons/Menu";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import _, { startCase } from "lodash";
import "./style.css";
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Check, CheckCircle, Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "calc(100dvh - 64px)",
    width: "100vw",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  formsList: {
    flex: 1,
    marginLeft: "1px",
    marginRight: "1px",
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    overflowY: "visible",
    justifyContent: "flex-start",
    scrollSnapType: "x mandatory",
    height: "calc(100dvh - 149px)",
    scrollBehavior: "smooth",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let formsRef = null;

// NOT OPTIMIZED AT ALL SHITTY RENDERS
// Modal showing the list of selectedWeek trainings where you can change the order of the trainings using drag and drop
const PlanningManagerModal = (props) => {
  const { handleClose, open } = props;
  console.log(props.data);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(
    Object.values({
      1: {
        key: "1",
        title: "Jour de récupération",
      },
      2: {
        key: "2",
        title: "Jour de récupération",
      },
      3: {
        key: "3",
        title: "Jour de récupération",
      },
      4: {
        key: "4",
        title: "Jour de récupération",
      },
      5: {
        key: "5",
        title: "Jour de récupération",
      },
      6: {
        key: "6",
        title: "Jour de récupération",
      },
      7: {
        key: "7",
        title: "Jour de récupération",
      },
      ...props.data,
    })
  );

  //Useless ?? Double render ?? This is trash
  useEffect(() => {
    setData(
      Object.values({
        1: {
          key: "1",
          title: "Jour de récupération",
        },
        2: {
          key: "2",
          title: "Jour de récupération",
        },
        3: {
          key: "3",
          title: "Jour de récupération",
        },
        4: {
          key: "4",
          title: "Jour de récupération",
        },
        5: {
          key: "5",
          title: "Jour de récupération",
        },
        6: {
          key: "6",
          title: "Jour de récupération",
        },
        7: {
          key: "7",
          title: "Jour de récupération",
        },
        ...props.data,
      })
    );
  }, [props.data]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      let response = await axios.patch(
        `https://online.korper.io/coachs/customer/${props.selectedCustomer._id}/trainings/order`,
        {
          newOrder: data,
          selectedYear: props.selectedYear,
          selectedWeek: props.selectedWeek,
        },
        { withCredentials: true }
      );

      if (response.data.success) {
        // get trainings
        props.getTrainings();
        handleClose();
      } else {
        toast.error("Erreur serveur, opération impossible");
      }
    } catch (error) {
      console.error(error);
      toast.error("Erreur serveur, opération impossible");
    } finally {
      setLoading(false);
    }
  };

  const getStartOfWeek = () => {
    if (!props.selectedWeek) {
      return moment().startOf("isoWeek");
    }

    return moment()
      .isoWeekYear(props.selectedYear)
      .isoWeek(props.selectedWeek)
      .startOf("isoWeek");
  };

  const [weekStatus, setWeekStatus] = useState(
    getStartOfWeek().isBefore(moment(), "week")
      ? "before"
      : getStartOfWeek().isSame(moment(), "week")
      ? "current"
      : "after"
  );

  useEffect(() => {
    setWeekStatus(
      getStartOfWeek().isBefore(moment(), "week")
        ? "before"
        : getStartOfWeek().isSame(moment(), "week")
        ? "current"
        : "after"
    );
  }, [props.selectedYear, props.selectedWeek]);

  const { staticData, dynamicData } = useMemo(() => {
    if (!data || !data.length) {
      return {
        staticData: [],
        dynamicData: [],
      };
    }

    let sliceOffset =
      weekStatus === "before"
        ? 7
        : weekStatus === "current"
        ? moment().isoWeekday() - 1
        : 0;

    console.log(weekStatus);

    if (data[sliceOffset] && data[sliceOffset].done) {
      sliceOffset += 1;
    }

    return {
      staticData: data.slice(0, sliceOffset),
      dynamicData: data.slice(sliceOffset, 7),
    };
  }, [data, weekStatus]);

  console.log("weekStatus", weekStatus);

  console.log("dynamicData", dynamicData);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(dynamicData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    console.log("items", items);

    setData([...staticData, ...items]);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "8px",
    border: "1px solid #f0f0f0",
    borderRadius: "4px",
    marginBottom: "8px",
    height: "80px",

    // change background colour if dragging
    backgroundColor: isDragging ? "#d4edff" : "transparent",
    // add animation on background color
    transition: "background-color 0.2s ease",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const days = useMemo(() => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(getStartOfWeek().add(i, "days"));
    }
    return days;
  }, []);

  const dayNames = ["LUN", "MAR", "MER", "JEU", "VEN", "SAM", "DIM"];

  return (
    <Dialog open={open}>
      <DialogTitle>
        <h3 style={{ textAlign: "center" }}>Organiser les séances</h3>
        <h4 style={{ textAlign: "center", color: "#737373" }}>
          {getStartOfWeek().format("DD MMM").toUpperCase()} -{" "}
          {getStartOfWeek().endOf("isoWeek").format("DD MMM").toUpperCase()}
        </h4>
      </DialogTitle>
      <DialogContent>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {/* Show week days column on the left aligned with items */}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {days.map((day, index) => {
              if (
                weekStatus === "before" ||
                (weekStatus === "current" && day.isBefore(moment(), "day"))
              ) {
                return (
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "80px",
                      marginBottom: "8px",
                      paddingRight: "12px",
                      color: "grey",
                    }}
                  >
                    <h4>{dayNames[index]}</h4>
                    <h5>{day.format("D")}</h5>
                  </Box>
                );
              }
              if (weekStatus === "current" && day.isSame(moment(), "day")) {
                return (
                  <Box
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "80px",
                      marginBottom: "8px",
                      paddingRight: "12px",
                      color: "#4180FA",
                    }}
                  >
                    <h4>{dayNames[index]}</h4>
                    <h5>{day.format("D")}</h5>
                  </Box>
                );
              }
              return (
                <Box
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "80px",
                    marginBottom: "8px",
                    paddingRight: "12px",
                  }}
                >
                  <h4>{dayNames[index]}</h4>
                  <h5>{day.format("D")}</h5>
                </Box>
              );
            })}
          </Box>
          <Box
            style={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
            }}
          >
            {staticData.map((item, index) => (
              <Box
                key={!!item._id ? item._id : item.key}
                style={getItemStyle()}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        item.image
                          ? `https://online.korper.io/files${item.image}`
                          : "https://i.pinimg.com/736x/ee/26/8c/ee268c084602fb5cba2ea028b000f9de.jpg"
                      }
                      alt="training"
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "4px",
                        marginRight: "8px",
                        objectFit: "cover",
                        filter: "grayscale(100%)",
                        opacity: 0.6,
                      }}
                    />
                    <div>
                      <h4 style={{ color: "#7c7c7c", fontSize: "15px" }}>
                        {item.title}
                      </h4>
                      {item.subtitle && (
                        <p
                          style={{
                            color: "#7c7c7c",
                            fontSize: "13px",
                            marginTop: "4px",
                            marginBottom: "2px",
                          }}
                        >
                          {item.subtitle}
                        </p>
                      )}
                      {item.totalTime && (
                        <p style={{ color: "#7c7c7c", fontSize: "13px" }}>
                          {Math.ceil(item.totalTime / 60)} min
                        </p>
                      )}
                    </div>
                  </div>

                  {!item._id ? null : item.done ? (
                    // green tick icon mui
                    <Check style={{ fontSize: "26px", color: "#4caf50" }} />
                  ) : (
                    <Close style={{ fontSize: "26px", color: "#f44336" }} />
                  )}
                </div>
              </Box>
            ))}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <Box
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      flexGrow: 1,
                    }}
                  >
                    {dynamicData.map((item, index) => (
                      <Draggable
                        key={!!item._id ? item._id : item.key}
                        draggableId={!!item._id ? item._id : item.key}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    item.image
                                      ? `https://online.korper.io/files${item.image}`
                                      : "https://i.pinimg.com/736x/ee/26/8c/ee268c084602fb5cba2ea028b000f9de.jpg"
                                  }
                                  alt="training"
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: "4px",
                                    marginRight: "8px",
                                    objectFit: "cover",
                                  }}
                                />
                                <div>
                                  <h4 style={{ fontSize: "15px" }}>
                                    {item.title}
                                  </h4>
                                  {item.subtitle && (
                                    <p
                                      style={{
                                        color: "#7c7c7c",
                                        fontSize: "13px",
                                        marginTop: "4px",
                                        marginBottom: "2px",
                                      }}
                                    >
                                      {item.subtitle}
                                    </p>
                                  )}
                                  {item.totalTime && (
                                    <p
                                      style={{
                                        color: "#7c7c7c",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {Math.ceil(item.totalTime / 60)} min
                                    </p>
                                  )}
                                </div>
                              </div>
                              {/* Drag handle icon */}
                              <DragHandleIcon
                                style={{ fontSize: "26px", color: "black" }}
                              />
                            </div>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={onSubmit} color="primary" variant="contained">
            Enregistrer
          </Button>
        </DialogActions>
      </DialogContent>
      {/* loading backdrop */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: loading ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    </Dialog>
  );
};

const CustomerListView = (props) => {
  const classes = useStyles();
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [planningManagerOpen, setPlanningManagerOpen] = useState(false);

  const [selectedYear, setSelectedYear] = useState(
    parseInt(moment().isoWeekYear())
  );
  const [selectedWeek, setSelectedWeek] = useState(
    parseInt(moment().isoWeek())
  );
  const today = parseInt(moment().isoWeekday());

  const [weekTrainings, setWeekTrainings] = useState({});

  const [loading, setLoading] = useState(false);

  const [initialScrollDone, setInitialScrollDone] = useState(false);

  const [customerListOpen, setCustomerListOpen] = useState(true);
  const [customerInfosOpen, setCustomerInfosOpen] = useState(true);
  const [updatePlanning, setUpdatePlanning] = React.useState(false);
  const [selectedToolbarDay, setSelectedToolbarDay] = useState(
    parseInt(moment().isoWeekday())
  );

  const [trainings, settrainings] = useState([]);

  const customerInforRef = useRef(null);
  const formsRef = useRef(null);
  const toolBarRef = useRef(null);

  const getTrainings = async () => {
    try {
      setLoading(true);
      let response = await axios.get(
        `https://online.korper.io/coachs/customer/${selectedCustomer._id}/trainings?year=${selectedYear}&week=${selectedWeek}`,
        { withCredentials: true }
      );

      if (response.data.success) {
        setWeekTrainings(response.data.weekTrainings);
        setLoading(false);
        customerInforRef?.current?.getWeek(selectedCustomer._id);
        fetchtest(selectedCustomer);

        if (!initialScrollDone) {
          if (formsRef?.current) {
            formsRef.current.scrollLeft = (today - 1) * 410;
          }
          setInitialScrollDone(true);
        }
      } else {
        setLoading(false);
        toast.error(
          "Erreur serveur, impossible de récupurer les entrainements de ce client"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error(
        "Erreur serveur, impossible de récupurer les entrainements de ce client"
      );
    }
  };

  const formValidator = (values) => {
    if (!values || !values.title || !values.subtitle || !values.image) {
      throw `Le formulaire est incomplet, vérifiez les informations`;
    }

    if (!values.circuits?.length) {
      throw `Aucun circuit n'a été ajouté`;
    }

    for (const circuit of values.circuits) {
      if (!circuit.name) {
        throw `Un circuit n'as pas de nom`;
      }
      if (!circuit.exercices?.length) {
        throw `Le circuit "${circuit.name}" n'a pas d'exercices`;
      }

      for (const exercice of circuit.exercices) {
        if (!exercice.temps && !exercice.reps) {
          throw `L'exercice "${exercice.name}" du circuit "${circuit.name}" n'a pas de temps ou de répetitions`;
        }
      }
    }

    return true;
  };

  const getProgStatus = async () => {
    try {
      let res = await axios.get("https://online.korper.io/coachs/prog/status", {
        withCredentials: true,
      });

      if (res.data.success) {
        console.log("here");
        console.log(res.data.progStatus);
        props.setProgStatus(res.data.progStatus);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = async (values, customerId, callback) => {
    try {
      let equipments = [];
      values.circuits.forEach((c) => {
        c.exercices.forEach((ex) => {
          if (ex.equipment) {
            ex.equipment.split(", ").forEach((v) => {
              if (v && !equipments.includes(v)) {
                equipments.push(v);
              }
            });
          }
        });
      });

      values.equipment = equipments.length
        ? equipments
        : ["Aucun équipement requis"];

      formValidator(values);
    } catch (error) {
      alert(error);
      return;
    }

    try {
      setLoading(true);
      let response = await axios.patch(
        `https://online.korper.io/coachs/customer/${customerId}/training`,
        values,
        { withCredentials: true }
      );

      if (response.data.success) {
        getProgStatus();
        getTrainings();
      } else if (response.data.reason === "done") {
        setLoading(false);
        alert(
          "Impossible de modifier cette séance, elle a été terminée par le client"
        );
        getTrainings();
      } else if (response.data.reason === "started") {
        setLoading(false);
        alert(
          "Impossible de modifier cette séance, elle a été commencée par le client"
        );
        getTrainings();
      } else {
        setLoading(false);
        toast.error("Erreur serveur, opération impossible");
      }
      callback();
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Erreur serveur, opération impossible");
    } finally {
      setUpdatePlanning(true);
    }
  };

  const getTrainingsWeek = async (customerId, year, week) => {
    try {
      let response = await axios.get(
        `https://online.korper.io/coachs/customer/${customerId._id}/trainings?year=${year}&week=${week}`,
        { withCredentials: true }
      );

      if (response.data.success) {
        return Object.values(response.data.weekTrainings);
      } else {
        toast.error(
          "Erreur serveur, impossible de récupurer les entrainements de ce client"
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "Erreur serveur, impossible de récupurer les entrainements de ce client"
      );
    }
  };

  const fetchtest = async (selectedCustomer) => {
    if (selectedCustomer) {
      if (selectedWeek == 1) {
        let week1 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek + 2
        ); //week 2
        let week2 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek + 1
        ); //week 1
        let week3 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek
        ); //week 0
        try {
          let week4 = await getTrainingsWeek(
            selectedCustomer,
            selectedYear - 1,
            53
          ); //week -1
          let week5 = await getTrainingsWeek(
            selectedCustomer,
            selectedYear - 1,
            52
          ); //week -2
          let week6 = await getTrainingsWeek(
            selectedCustomer,
            selectedYear - 1,
            51
          ); //week -3
          settrainings([
            [...week1],
            [...week2],
            [...week3],
            [...week4],
            [...week5],
            [...week6],
          ]);
        } catch (err) {
          let week4 = await getTrainingsWeek(
            selectedCustomer,
            selectedYear - 1,
            52
          ); //week -1
          let week5 = await getTrainingsWeek(
            selectedCustomer,
            selectedYear - 1,
            51
          ); //week -2
          let week6 = await getTrainingsWeek(
            selectedCustomer,
            selectedYear - 1,
            50
          ); //week -3
          settrainings([
            [...week1],
            [...week2],
            [...week3],
            [...week4],
            [...week5],
            [...week6],
          ]);
        }
      } else if (selectedWeek == 2) {
        let week1 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek + 2
        ); //week 2
        let week2 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek + 1
        ); //week 1
        let week3 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek
        ); //week 0
        let week4 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek - 1
        ); //week -1
        try {
          let week5 = await getTrainingsWeek(
            selectedCustomer,
            selectedYear - 1,
            53
          ); //week -2
          let week6 = await getTrainingsWeek(
            selectedCustomer,
            selectedYear - 1,
            52
          ); //week -3
          settrainings([
            [...week1],
            [...week2],
            [...week3],
            [...week4],
            [...week5],
            [...week6],
          ]);
        } catch (err) {
          let week5 = await getTrainingsWeek(
            selectedCustomer,
            selectedYear - 1,
            52
          ); //week -2
          let week6 = await getTrainingsWeek(
            selectedCustomer,
            selectedYear - 1,
            51
          ); //week -3
          settrainings([
            [...week1],
            [...week2],
            [...week3],
            [...week4],
            [...week5],
            [...week6],
          ]);
        }
      } else if (selectedWeek == 3) {
        let week1 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek + 2
        ); //week 2
        let week2 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek + 1
        ); //week 1
        let week3 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek
        ); //week 0
        let week4 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek - 1
        ); //week -1
        let week5 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek - 2
        ); //week -2
        try {
          let week6 = await getTrainingsWeek(
            selectedCustomer,
            selectedYear - 1,
            53
          ); //week -3
          settrainings([
            [...week1],
            [...week2],
            [...week3],
            [...week4],
            [...week5],
            [...week6],
          ]);
        } catch (err) {
          let week6 = await getTrainingsWeek(
            selectedCustomer,
            selectedYear - 1,
            52
          ); //week -3
          settrainings([
            [...week1],
            [...week2],
            [...week3],
            [...week4],
            [...week5],
            [...week6],
          ]);
        }
      } else {
        let week1 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek + 2
        ); //week 2
        let week2 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek + 1
        ); //week 1
        let week3 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek
        ); //week 0
        let week4 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek - 1
        ); //week -1
        let week5 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek - 2
        ); //week -2
        let week6 = await getTrainingsWeek(
          selectedCustomer,
          selectedYear,
          selectedWeek - 3
        ); //week -3
        settrainings([
          [...week1],
          [...week2],
          [...week3],
          [...week4],
          [...week5],
          [...week6],
        ]);
      }
    }
  };

  const onDelete = async (values, customerId) => {
    try {
      setLoading(true);
      let response = await axios.delete(
        `https://online.korper.io/coachs/customer/${customerId}/training?year=${values.date.year}&week=${values.date.week}&day=${values.date.day}`,
        { withCredentials: true }
      );

      if (response.data.success) {
        getProgStatus();
        getTrainings();
      } else {
        toast.error("Erreur serveur, opération impossible");
      }
    } catch (error) {
      console.error(error);
      toast.error("Erreur serveur, opération impossible");
    } finally {
      setLoading(false);
    }
  };

  const exportTemplate = async (values) => {
    try {
      formValidator(values);
    } catch (error) {
      alert(error);
      return;
    }

    try {
      setLoading(true);
      let response = await axios.post(
        `https://online.korper.io/coachs/template`,
        values,
        { withCredentials: true }
      );

      if (response.data.success) {
        setLoading(false);
        toast.success(`Le modèle "${values.name}" a été crée`);
      } else {
        setLoading(false);
        toast.error("Erreur serveur, opération toast impossible");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Erreur serveur, opération impossible");
    }
  };

  const search = useLocation().search;

  useEffect(() => {
    const customerId = new URLSearchParams(search).get("customer");

    if (customerId) {
      let foundCustomer = props.students?.find((v) => v._id === customerId);

      if (foundCustomer) {
        setSelectedCustomer(foundCustomer);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      getTrainings();
      if (formsRef?.current) {
        setSelectedToolbarDay(1);
        formsRef.current.scrollLeft = (1 - 1) * 410;
      }
    }
  }, [selectedCustomer, selectedWeek]);

  const onCustomerSelect = async (cus) => {
    setSelectedCustomer(cus);
    window.history.replaceState(null, "", `?customer=${cus._id}`);
  };

  const sendTrainingWeek = async () => {
    try {
      let response = await axios.post(
        `https://online.korper.io/coachs/trainings/week/${selectedCustomer._id}`,
        { week: selectedWeek, year: selectedYear },
        { withCredentials: true }
      );

      if (response.data.success) {
        toast.success("Entrainements de cette semaine envoyés");
        getProgStatus();
        getTrainings();
      } else {
        toast.error(
          "Erreur serveur, impossible d'envoyer les entrainements de ce client"
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "Erreur serveur, impossible d'envoyer les entrainements de ce client"
      );
    }
  };

  let sendButtonDisabled = useMemo(() => {
    let keys = Object.keys(weekTrainings);

    if (!keys.length) return true;

    for (let key of keys) {
      if (weekTrainings[key]._id && weekTrainings[key].available !== true) {
        return false;
      }
    }

    return true;
  }, [weekTrainings]);

  return (
    <Page className={classes.root} title="Plan d’entrainement">
      <Container
        id="ContainerToolbar"
        maxWidth={false}
        style={{
          width: "100%",
          display: "flex",
          maxHeight: "-webkit-fill-available",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "8px",
        }}
      >
        <Button
          size="small"
          onClick={() => setCustomerListOpen(!customerListOpen)}
        >
          {customerListOpen ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ArrowBackIosIcon
                style={{
                  fontSize: "18px",
                  color: "grey",
                  verticalAlign: "super",
                }}
              />
              <MenuIcon style={{ fontSize: "30px", color: "grey" }} />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <MenuIcon style={{ fontSize: "30px", color: "grey" }} />
              <ArrowForwardIosIcon
                style={{
                  fontSize: "18px",
                  color: "grey",
                  verticalAlign: "super",
                }}
              />
            </div>
          )}
        </Button>
        <div
          style={{
            display: "flex",
            position: "relative",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Toolbar
            style={{}}
            ref={toolBarRef}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            selectedWeek={selectedWeek}
            setSelectedWeek={setSelectedWeek}
            formsRef={formsRef}
            selectedToolbarDay={selectedToolbarDay}
            setSelectedToolbarDay={setSelectedToolbarDay}
          />
          <IconButton
            style={{ position: "absolute", right: "-60px" }}
            onClick={() => setPlanningManagerOpen(true)}
          >
            <img
              src="../static/images/recouvrir.png"
              style={{
                width: "22px",
                height: "22px",
              }}
            />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              marginRight: "5px",
              backgroundColor: sendButtonDisabled ? undefined : "#4caf50",
              color: sendButtonDisabled ? undefined : "white",
            }}
            disabled={sendButtonDisabled}
            onClick={sendTrainingWeek}
            color="inherit"
            variant="contained"
          >
            Envoyer
          </Button>
          <Button
            id="leftbtnToolbar"
            size="small"
            onClick={() => setCustomerInfosOpen(!customerInfosOpen)}
          >
            {customerInfosOpen ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <MenuIcon style={{ fontSize: "30px", color: "grey" }} />
                <ArrowForwardIosIcon
                  style={{
                    fontSize: "18px",
                    color: "grey",
                    verticalAlign: "super",
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ArrowBackIosIcon
                  style={{
                    fontSize: "18px",
                    color: "grey",
                    verticalAlign: "super",
                  }}
                />
                <MenuIcon style={{ fontSize: "30px", color: "grey" }} />
              </div>
            )}
          </Button>
        </div>
      </Container>
      <Container
        maxWidth={false}
        style={{
          flex: "1 1",
          display: "flex",
          contain: "size",
          maxHeight: "-webkit-fill-available",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <Results
          open={customerListOpen}
          selectedCustomer={selectedCustomer}
          onCustomerSelect={onCustomerSelect}
        />
        <div
          className={classes.formsList}
          ref={formsRef}
          onScroll={() => toolBarRef?.current?.onFormsScroll()}
        >
          <TrainingProgramForm
            onSubmit={onSubmit}
            onDelete={onDelete}
            exportTemplate={exportTemplate}
            weekTrainings={weekTrainings}
            selectedCustomer={selectedCustomer}
            selectedYear={selectedYear}
            selectedWeek={selectedWeek}
            selectedDay={1}
            selectedToolbarDay={selectedToolbarDay}
            setSelectedToolbarDay={setSelectedToolbarDay}
            trainings={trainings}
            getTrainingsWeek={getTrainingsWeek}
          />
          <TrainingProgramForm
            onSubmit={onSubmit}
            onDelete={onDelete}
            exportTemplate={exportTemplate}
            weekTrainings={weekTrainings}
            selectedCustomer={selectedCustomer}
            selectedYear={selectedYear}
            selectedWeek={selectedWeek}
            selectedDay={2}
            selectedToolbarDay={selectedToolbarDay}
            setSelectedToolbarDay={setSelectedToolbarDay}
            trainings={trainings}
            getTrainingsWeek={getTrainingsWeek}
          />
          <TrainingProgramForm
            onSubmit={onSubmit}
            onDelete={onDelete}
            exportTemplate={exportTemplate}
            weekTrainings={weekTrainings}
            selectedCustomer={selectedCustomer}
            selectedYear={selectedYear}
            selectedWeek={selectedWeek}
            selectedDay={3}
            selectedToolbarDay={selectedToolbarDay}
            setSelectedToolbarDay={setSelectedToolbarDay}
            trainings={trainings}
            getTrainingsWeek={getTrainingsWeek}
          />
          <TrainingProgramForm
            onSubmit={onSubmit}
            onDelete={onDelete}
            exportTemplate={exportTemplate}
            weekTrainings={weekTrainings}
            selectedCustomer={selectedCustomer}
            selectedYear={selectedYear}
            selectedWeek={selectedWeek}
            selectedDay={4}
            selectedToolbarDay={selectedToolbarDay}
            setSelectedToolbarDay={setSelectedToolbarDay}
            trainings={trainings}
            getTrainingsWeek={getTrainingsWeek}
          />
          <TrainingProgramForm
            onSubmit={onSubmit}
            onDelete={onDelete}
            exportTemplate={exportTemplate}
            weekTrainings={weekTrainings}
            selectedCustomer={selectedCustomer}
            selectedYear={selectedYear}
            selectedWeek={selectedWeek}
            selectedDay={5}
            selectedToolbarDay={selectedToolbarDay}
            setSelectedToolbarDay={setSelectedToolbarDay}
            trainings={trainings}
            getTrainingsWeek={getTrainingsWeek}
          />
          <TrainingProgramForm
            onSubmit={onSubmit}
            onDelete={onDelete}
            exportTemplate={exportTemplate}
            weekTrainings={weekTrainings}
            selectedCustomer={selectedCustomer}
            selectedYear={selectedYear}
            selectedWeek={selectedWeek}
            selectedDay={6}
            selectedToolbarDay={selectedToolbarDay}
            setSelectedToolbarDay={setSelectedToolbarDay}
            trainings={trainings}
            getTrainingsWeek={getTrainingsWeek}
          />
          <TrainingProgramForm
            onSubmit={onSubmit}
            onDelete={onDelete}
            exportTemplate={exportTemplate}
            weekTrainings={weekTrainings}
            selectedCustomer={selectedCustomer}
            selectedYear={selectedYear}
            selectedWeek={selectedWeek}
            selectedDay={7}
            selectedToolbarDay={selectedToolbarDay}
            setSelectedToolbarDay={setSelectedToolbarDay}
            trainings={trainings}
            getTrainingsWeek={getTrainingsWeek}
          />
        </div>
        <CustomerInfos
          ref={customerInforRef}
          trainingProgram
          open={customerInfosOpen}
          updatePlanning={updatePlanning}
          setUpdatePlanning={setUpdatePlanning}
          selectedCustomer={selectedCustomer}
        />
      </Container>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <PlanningManagerModal
        open={planningManagerOpen}
        handleClose={() => setPlanningManagerOpen(false)}
        data={weekTrainings}
        selectedCustomer={selectedCustomer}
        selectedYear={selectedYear}
        selectedWeek={selectedWeek}
        getTrainings={getTrainings}
      />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    students: state.auth.students,
  };
};

const mapDispatchToProps = {
  setProgStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListView);
