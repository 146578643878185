import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SendIcon from "@material-ui/icons/Send";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Tooltip } from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { toast } from "react-toastify";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  ListItemAvatar,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  makeStyles,
  Tabs,
  Tab,
  Button,
  CircularProgress,
  TextField,
  FormGroup,
  FormControl,
  FormControlLabel,
  IconButton,
  Popover,
} from "@material-ui/core";
import getInitials from "../../../utils/getInitials";
import { connect } from "react-redux";
import { updateStudent } from "../../../korper-redux/actions";
import axios from "axios";
import moment from "moment";
import "./style.css";
import ModalImage from "react-modal-image";
import { Warning } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 1",
    width: "0px",
    maxWidth: "0px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: "auto",
    overflow: "hidden",
  },
  rootTrainingProgram: {
    flex: "1 1",
    width: "0px",
    maxWidth: "0px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: "calc(100dvh - 150px)",
  },
  open: {
    width: "325px",
    maxWidth: "325px",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  tabRoot: {
    minWidth: 0,
    fontSize: 13,
    padding: "6px 6px",
  },
  dayContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: 8,
    marginRight: 8,
  },
  dayCircle: {
    display: "flex",
    width: "34px",
    height: "34px",
    borderRadius: "17px",
    border: "solid 1px #585858",
    borderColor: "#585858",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 4,
  },
  dayCircleGreen: {
    display: "flex",
    width: "34px",
    height: "34px",
    borderRadius: "17px",
    border: "solid 2px #585858",
    borderColor: "#20F75A",
    backgroundColor: "#20F75A70",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 4,
  },
  dayCircleRed: {
    display: "flex",
    width: "34px",
    height: "34px",
    borderRadius: "17px",
    border: "solid 2px #585858",
    borderColor: "#F72020",
    backgroundColor: "#F7202070",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 4,
  },
  dayCirclePurple: {
    display: "flex",
    width: "34px",
    height: "34px",
    borderRadius: "17px",
    border: "solid 2px #585858",
    borderColor: "#4FFBDF",
    backgroundColor: "#4FFBDF70",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 4,
  },
  dayCircleGrey: {
    display: "flex",
    width: "34px",
    height: "34px",
    borderRadius: "17px",
    border: "solid 2px #585858",
    borderColor: "#d4d4d4",
    backgroundColor: "#d4d4d430",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 4,
  },
}));

const CustomerOnboardingModal = (props) => {
  const { handleClose, open, customer } = props;

  const [loading, setLoading] = useState(false);

  const [activity, setActivity] = useState(customer?.sheet?.activity || "");
  const [planning, setPlanning] = useState(customer?.sheet?.planning || "");
  const [health, setHealth] = useState(customer?.sheet?.health || "");
  const [goals, setGoals] = useState(customer?.sheet?.goals || "");
  const [location, setLocation] = useState(customer?.sheet?.location || "");
  const [trainingDays, setTrainingDays] = useState(
    customer?.sheet?.trainingDays || ""
  );
  const [trainingDuration, setTrainingDuration] = useState(
    customer?.sheet?.trainingDuration || ""
  );
  const [infos, setInfos] = useState(customer?.sheet?.infos || "");
  const [startingDate, setStartingDate] = useState(
    customer?.sheet?.startingDate ||
      (customer?.trainingStarted
        ? moment(customer?.trainingStarted).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"))
  );

  useEffect(() => {
    setActivity(props.customer?.sheet?.activity || "");
    setPlanning(props.customer?.sheet?.planning || "");
    setHealth(props.customer?.sheet?.health || "");
    setGoals(props.customer?.sheet?.goals || "");
    setLocation(props.customer?.sheet?.location || "");
    setTrainingDays(props.customer?.sheet?.trainingDays || "");
    setTrainingDuration(props.customer?.sheet?.trainingDuration || "");
    setInfos(props.customer?.sheet?.infos || "");
    setStartingDate(
      props.customer?.sheet?.startingDate ||
        (props.customer?.trainingStarted
          ? moment(props.customer?.trainingStarted).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"))
    );
  }, [props.customer]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      let body = {
        activity,
        planning,
        health,
        goals,
        location,
        trainingDays,
        trainingDuration,
        infos,
        startingDate,
      };
      let response = await axios.patch(
        `https://online.korper.io/coachs/customer/${customer._id}/sheet`,
        body,
        { withCredentials: true }
      );

      if (response.data.success) {
        updateStudent(response.data.customer);
        handleClose();
      } else {
        toast.error("Erreur serveur, opération impossible");
      }
    } catch (error) {
      console.error(error);
      toast.error("Erreur serveur, opération impossible");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <DialogTitle>Fiche client</DialogTitle>
      <DialogContent>
        <TextField
          style={{ marginBottom: "10px" }}
          fullWidth
          label="Activité"
          name="activity"
          onChange={(e) => setActivity(e.target.value)}
          value={activity}
          size="small"
          multiline
        />
        <TextField
          style={{ marginBottom: "10px" }}
          fullWidth
          label="Planning type"
          name="planning"
          onChange={(e) => setPlanning(e.target.value)}
          value={planning}
          size="small"
          multiline
        />
        <TextField
          style={{ marginBottom: "10px" }}
          fullWidth
          label="Problèmes de santé / douleurs"
          name="health"
          onChange={(e) => setHealth(e.target.value)}
          value={health}
          size="small"
          multiline
        />
        <TextField
          style={{ marginBottom: "10px" }}
          fullWidth
          label="Objectifs"
          name="goals"
          onChange={(e) => setGoals(e.target.value)}
          value={goals}
          size="small"
          multiline
        />
        <TextField
          style={{ marginBottom: "10px" }}
          fullWidth
          label="Lieu d'entraînement"
          name="location"
          onChange={(e) => setLocation(e.target.value)}
          value={location}
          size="small"
          multiline
        />
        <TextField
          style={{ marginBottom: "10px" }}
          fullWidth
          label="Jours d'entrainement"
          name="trainingDays"
          onChange={(e) => setTrainingDays(e.target.value)}
          value={trainingDays}
          size="small"
          multiline
        />
        <TextField
          style={{ marginBottom: "10px" }}
          fullWidth
          label="Durée des entrainements"
          name="trainingDuration"
          onChange={(e) => setTrainingDuration(e.target.value)}
          value={trainingDuration}
          size="small"
          multiline
        />
        <TextField
          style={{ marginBottom: "10px" }}
          fullWidth
          label="Informations diverses"
          name="infos"
          onChange={(e) => setInfos(e.target.value)}
          value={infos}
          size="small"
          multiline
        />
        <TextField
          style={{ marginBottom: "10px" }}
          fullWidth
          label="Date du premier entrainement"
          name="startingDate"
          onChange={(e) => setStartingDate(e.target.value)}
          value={startingDate}
          size="small"
          type="date"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Fermer</Button>
        <Button onClick={onSubmit} disabled={loading} color="primary">
          Enregister
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CustomerInfos = forwardRef(
  (
    {
      className,
      customers,
      user,
      selectedCustomer,
      updatePlanning,
      showTrainingLink,
      onCustomerUpdate,
      open,
      props,
      trainingProgram,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles();

    const [value, setValue] = React.useState(0);
    const [noteValue, setNoteValue] = React.useState(
      selectedCustomer?.note || ""
    );
    const [stats, setStats] = React.useState(null);
    const [statsLoading, setStatsLoading] = React.useState(false);
    const [week, setWeek] = React.useState(null);
    const [lastWeek, setLastWeek] = React.useState(null);
    const [weekLoading, setWeekLoading] = React.useState(false);
    const [customerOnboardingModalOpen, setCustomerOnboardingModalOpen] =
      React.useState(false);

    useImperativeHandle(
      ref,
      () => {
        return {
          getWeek,
        };
      },
      []
    );

    const getStats = async (customerId) => {
      try {
        setStatsLoading(true);
        let response = await axios.get(
          `https://online.korper.io/coachs/customer/${customerId}/stats`,
          { withCredentials: true }
        );

        if (response.data.success) {
          setStats({
            month: response.data.month,
          });
          setStatsLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getWeek = async (customerId) => {
      try {
        setWeekLoading(true);
        let response = await axios.get(
          `https://online.korper.io/coachs/customer/${customerId}/week`,
          { withCredentials: true }
        );

        if (response.data.success) {
          setLastWeek(response.data.lastWeek);
          setWeek(response.data.week);
          setWeekLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      setNoteValue(selectedCustomer?.note || "");

      if (selectedCustomer) {
        getStats(selectedCustomer._id);
        getWeek(selectedCustomer._id);
      }
    }, [selectedCustomer]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const onNoteSubmit = async (id) => {
      try {
        let response = await axios.patch(
          `https://online.korper.io/coachs/customer/${id}/note`,
          { note: noteValue },
          { withCredentials: true }
        );

        if (response.data.success) {
          rest.updateStudent(response.data.customer);
        } else {
          toast.error("Erreur serveur, opération impossible");
        }
      } catch (error) {
        console.error(error);
        toast.error("Erreur serveur, opération impossible");
      }
    };

    const formatMinutes = (minutes) => {
      return `${`0${Math.floor(minutes / 60)}`.slice(-2)}:${`0${
        minutes % 60
      }`.slice(-2)}`;
    };

    const statusClasses = {
      none: "dayCircle",
      done: "dayCircleGreen",
      missed: "dayCircleRed",
      todo: "dayCirclePurple",
      copied: "dayCircleGrey"
    };
    const newBadge = (createdAt) => {
      const ageAccount = moment().diff(moment(createdAt), "days", true);
      const dateZone = moment(createdAt).isBetween(
        moment("2023-04-21"),
        moment(),
        null,
        "[]"
      );
      //console.log(ageAccount,dateZone,ageAccount < 14 && dateZone,customer.firstName)
      return ageAccount < 14 && dateZone;
    };

    const [anchorIndex, setAnchorIndex] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [notes, setNotes] = useState([]);
    const inputRef = useRef(null);

    useEffect(() => {
      if (selectedCustomer) getNotes();
    }, [selectedCustomer]);

    const uploadNotes = async (newNotes) => {
      try {
        let response = await axios.patch(
          `https://online.korper.io/coachs/customer/${selectedCustomer._id}/notes`,
          { notes: newNotes },
          { withCredentials: true }
        );

        if (response.data.success) {
          if (response.data.notes) {
            setNotes(response.data.notes.list);
          } else {
            setNotes([]);
          }
        } else {
          toast.error(
            "Erreur serveur, impossible de modifier les notes de ce client"
          );
        }
      } catch (error) {
        console.error(error);
        toast.error(
          "Erreur serveur, impossible de modifier les notes de ce client"
        );
      }
    };
    const getNotes = async () => {
      try {
        let response = await axios.get(
          `https://online.korper.io/coachs/customer/${selectedCustomer._id}/notes`,
          { withCredentials: true }
        );

        if (response.data.success) {
          if (response.data.notes) {
            setNotes(response.data.notes.list);
          } else {
            setNotes([]);
          }
        } else {
          toast.error(
            "Erreur serveur, impossible de recupérer les notes de ce client"
          );
        }
      } catch (error) {
        console.error(error);
        toast.error(
          "Erreur serveur, impossible de recupérer les notes de ce client"
        );
      }
    };

    const handleBlur = (index, e) => {
      //alert('Vous avez quitté le champ de saisie');
      let newNotes = _.cloneDeep(notes);
      newNotes[index] = { ...notes[index], text: e.target.value };
      let trimNotes = newNotes.filter((item) => !!item.text);
      setNotes(trimNotes);
      //uploadNotes(trimNotes);
    };

    const addBlankNote = () => {
      let newNotes = _.cloneDeep(notes);
      newNotes.push({ text: "" });
      setNotes(newNotes);
    };

    const handleDelete = (index) => {
      const updatedNote = notes.filter((_, i) => i !== index); // Supprime l'index spécifié du tableau
      uploadNotes(updatedNote);
      setAnchorEl(null);
    };
    const handlePopover = (index) => (e) => {
      e.stopPropagation();
      setAnchorIndex(index);
      setAnchorEl(e.currentTarget);
    };
    const handleClose = (e) => {
      e.stopPropagation();
      setAnchorEl(null);
      setAnchorIndex(null);
    };

    return (
      <Card
        id="CustomerInfo"
        className={
          open && selectedCustomer
            ? clsx(
                trainingProgram ? classes.rootTrainingProgram : classes.root,
                className,
                classes.open
              )
            : clsx(classes.root, className)
        }
        {...rest}
      >
        <PerfectScrollbar>
          <Box>
            <div
              style={{
                margin: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {!selectedCustomer?.profilePicture ? (
                <Avatar
                  className={classes.avatar}
                  src={selectedCustomer?.profilePicture}
                >
                  {getInitials(
                    `${selectedCustomer?.firstName} ${selectedCustomer?.lastName}`
                  )}
                </Avatar>
              ) : (
                <ModalImage
                  small={selectedCustomer?.profilePicture}
                  large={selectedCustomer?.profilePicture}
                  hideDownload
                  className="avatar-preview"
                />
              )}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{ fontSize: 19, fontWeight: "600" }}
                  >{`${selectedCustomer?.firstName} ${selectedCustomer?.lastName}`}</span>

                  {newBadge(selectedCustomer?.createdAt) ? (
                    <div
                      style={{
                        background: "black",
                        color: "white",
                        padding: "2px 6px 2px 6px",
                        fontSize: "10px",
                        borderRadius: "15px",
                        marginLeft: "10px",
                      }}
                    >
                      New
                    </div>
                  ) : null}
                </div>
                <span
                  style={{ fontSize: 10, fontWeight: "400", color: "#7B7B7B" }}
                >
                  Membre depuis le{" "}
                  {moment(selectedCustomer?.createdAt).format("DD/MM/YY")}
                </span>
              </div>
            </div>
            <div id="groupButton">
              {showTrainingLink && (
                <RouterLink
                  to={`/app/customers?customer=${selectedCustomer?._id}`}
                >
                  <Button
                    variant="outlined"
                    style={{
                      fontSize: "12px",
                      minWidth: 240,
                      borderRadius: "18px",
                      margin: "5px 10px 5px 10px",
                    }}
                  >
                    Plan d’entrainement
                  </Button>
                </RouterLink>
              )}
              <Button
                onClick={() => setCustomerOnboardingModalOpen(true)}
                color="primary"
                variant="contained"
                style={{
                  fontSize: "12px",
                  minWidth: 240,
                  borderRadius: "18px",
                  margin: "5px 10px 5px 10px",
                  marginBottom: "10px",
                }}
              >
                Compléter la fiche client
              </Button>
            </div>
            {moment(selectedCustomer?.checkinCallDate).isAfter(moment()) && (
              <div
                id="warning-call"
                style={{
                  backgroundColor: "orange",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "solid 1px black",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
              >
                <img
                  src={"../static/images/video-camera2.png"}
                  style={{ width: 20, height: 20, marginRight: "10px" }}
                />
                <p style={{ fontWeight: "500", fontSize: "12px" }}>
                  Appel vidéo prévu{" "}
                  {moment(selectedCustomer?.checkinCallDate).calendar()}
                </p>
              </div>
            )}
            {!!selectedCustomer?.cancelAtPeriodEnd && (
              <div
                id="warning-call"
                style={{
                  backgroundColor: "orange",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "solid 1px black",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
              >
                <img
                  src="../static/images/member-card.png"
                  style={{ width: 20, height: 20, marginRight: "6px" }}
                />
                <p style={{ fontWeight: "500", fontSize: "12px" }}>
                  Résilié - fin de l'abonnement :{" "}
                  {moment(selectedCustomer?.currentPeriodEnd * 1000).format(
                    "DD/MM/YYYY"
                  )}
                </p>
              </div>
            )}
            {selectedCustomer && (
              <TaskNote selectedCustomer={selectedCustomer} />
            )}
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab classes={{ root: classes.tabRoot }} label="Activité" />
              <Tab classes={{ root: classes.tabRoot }} label="Informations" />
              <Tab classes={{ root: classes.tabRoot }} label="Contact" />
            </Tabs>

            {value === 0 && (
              <>
                <div style={{ padding: "20px 15px" }}>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "600",
                      marginTop: 10,
                      marginBottom: 16,
                    }}
                  >
                    Cette semaine
                  </div>
                  {weekLoading || !week ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {week.days.map((day) => (
                        <div key={day.date} className={classes.dayContainer}>
                          <div className={classes[statusClasses[day.status]]}>
                            <span style={{ fontSize: 13, fontWeight: "700" }}>
                              {day.date}
                            </span>
                          </div>
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: "700",
                              color: day.isToday ? "black" : "#838383",
                            }}
                          >
                            {day.letter}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "600",
                      marginTop: 10,
                      marginBottom: 16,
                    }}
                  >
                    Semaine précédente
                  </div>
                  {weekLoading || !week ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {lastWeek.days.map((day) => (
                        <div key={day.date} className={classes.dayContainer}>
                          <div className={classes[statusClasses[day.status]]}>
                            <span style={{ fontSize: 13, fontWeight: "700" }}>
                              {day.date}
                            </span>
                          </div>
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: "700",
                              color: day.isToday ? "black" : "#838383",
                            }}
                          >
                            {day.letter}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div>
                  <div
                    style={{
                      padding: "20px 15px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        fontSize: 16,
                        fontWeight: "600",
                        color: "#898989",
                      }}
                    >
                      Notes
                    </p>
                    <IconButton onClick={() => addBlankNote()}>
                      <AddIcon
                        style={{
                          fontSize: "18px",
                          marginRight: "2px",
                          color: "#3888FF",
                          background: "#DCDCDC",
                          borderRadius: "15px",
                        }}
                        variant="contained"
                      />
                    </IconButton>
                  </div>
                  <div>
                    {notes?.map((v, index) => (
                      <div id="note-speparator">
                        <FormControl
                          className="noteList-note"
                          style={{
                            background: "white",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          key={index}
                        >
                          <TextField
                            defaultValue={v.text}
                            onBlur={(e) => handleBlur(index, e)}
                            multiline
                            className={"task-input"}
                            variant="outlined"
                          />
                          <div className="taskList-button">
                            <IconButton
                              id="moreicon"
                              type="button"
                              aria-label="more"
                              onClick={handlePopover(index)}
                            >
                              <MoreVertIcon />
                            </IconButton>

                            <Popover
                              open={open && index === anchorIndex}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Button
                                  onClick={(e) => [
                                    e.stopPropagation(),
                                    handleDelete(index),
                                  ]}
                                  style={{
                                    padding: "8px 10px 8px 10px",
                                    textTransform: "none",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <DeleteForeverIcon
                                      style={{
                                        marginRight: "10px",
                                        color: "red",
                                      }}
                                    />
                                    Supprimer la tâche
                                  </Typography>
                                </Button>
                              </div>
                            </Popover>
                          </div>
                        </FormControl>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    padding: "20px 15px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "600",
                      marginBottom: 16,
                    }}
                  >
                    Statistiques{" "}
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#7B7B7B",
                      }}
                    >
                      (30 derniers jours)
                    </span>
                  </span>
                  {statsLoading || !stats ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: 15,
                        }}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: "400",
                            color: "#7B7B7B",
                          }}
                        >
                          Activité
                        </span>
                        <span style={{ fontSize: 19, fontWeight: "400" }}>
                          {stats.month.trainingsDone} jour(s)
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: 15,
                        }}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: "400",
                            color: "#7B7B7B",
                          }}
                        >
                          Calories
                        </span>
                        <span style={{ fontSize: 12, fontWeight: "600" }}>
                          {stats.month.totalCalories}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: 15,
                        }}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: "400",
                            color: "#7B7B7B",
                          }}
                        >
                          BPM Moy.
                        </span>
                        <span style={{ fontSize: 12, fontWeight: "600" }}>
                          {stats.month.averageBpm}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: 15,
                        }}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: "400",
                            color: "#7B7B7B",
                          }}
                        >
                          Temps d’entraînement
                        </span>
                        <span style={{ fontSize: 12, fontWeight: "600" }}>
                          {formatMinutes(stats.month.totalMinutes)} h
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: 15,
                        }}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: "400",
                            color: "#7B7B7B",
                          }}
                        >
                          Taux de séance{" "}
                        </span>
                        <span style={{ fontSize: 12, fontWeight: "600" }}>
                          {stats.month.trainingsDone +
                            stats.month.trainingsMissed <=
                          0
                            ? 100
                            : Math.round(
                                (stats.month.trainingsDone /
                                  (stats.month.trainingsDone +
                                    stats.month.trainingsMissed)) *
                                  100
                              )}
                          %
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: 15,
                        }}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: "400",
                            color: "#7B7B7B",
                          }}
                        >
                          Séances validées
                        </span>
                        <span style={{ fontSize: 12, fontWeight: "600" }}>
                          {stats.month.trainingsDone}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: 15,
                        }}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: "400",
                            color: "#7B7B7B",
                          }}
                        >
                          Séances ratées
                        </span>
                        <span style={{ fontSize: 12, fontWeight: "600" }}>
                          {stats.month.trainingsMissed}
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: 15,
                        }}
                      >
                        <span
                          style={{
                            fontSize: 12,
                            fontWeight: "400",
                            color: "#7B7B7B",
                          }}
                        >
                          Note Moy.
                        </span>
                        <span style={{ fontSize: 12, fontWeight: "600" }}>
                          {
                            [
                              "Aucune difficulté choisie",
                              "1 - Très facile",
                              "2 - Facile",
                              "3 - Assez facile",
                              "4 - Peu éprouvant",
                              "5 - Modéré",
                              "6 - Intense",
                              "7 - Assez difficile",
                              "8 - Difficile",
                              "9 - Très difficile",
                              "10 - Trop difficile ",
                            ][stats.month.averageDifficulty]
                          }
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            {value === 1 && (
              <div
                style={{
                  padding: "20px 15px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: "600",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  Physique
                </span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                >
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#7B7B7B",
                    }}
                  >
                    Taille
                  </span>
                  <span style={{ fontSize: 12, fontWeight: "600" }}>
                    {selectedCustomer?.height
                      ? `${selectedCustomer.height} cm`
                      : "-"}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                >
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#7B7B7B",
                    }}
                  >
                    Poids
                  </span>
                  <span style={{ fontSize: 12, fontWeight: "600" }}>
                    {selectedCustomer?.weight
                      ? `${selectedCustomer.weight} kg`
                      : "-"}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                >
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#7B7B7B",
                    }}
                  >
                    Age
                  </span>
                  <span style={{ fontSize: 12, fontWeight: "600" }}>
                    {selectedCustomer?.birthDate
                      ? `${moment().diff(
                          selectedCustomer?.birthDate,
                          "years"
                        )} ans`
                      : "-"}
                  </span>
                </div>
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: "600",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  Profil
                </span>
                {console.log(selectedCustomer?.survey)}
                {selectedCustomer?.survey && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 20,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 12,
                          fontWeight: "400",
                          color: "#7B7B7B",
                        }}
                      >
                        Objectif
                      </span>
                      <span style={{ fontSize: 12, fontWeight: "600" }}>
                        {selectedCustomer.survey &&
                        Number.isInteger(selectedCustomer.survey[6])
                          ? [
                              "Perdre du poids",
                              "Prendre du muscle",
                              "Bien-être et santé",
                              "Performances sportives",
                            ][selectedCustomer.survey[6]]
                          : "-"}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 20,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 12,
                          fontWeight: "400",
                          color: "#7B7B7B",
                        }}
                      >
                        Fréquence d’activité
                      </span>
                      <span style={{ fontSize: 12, fontWeight: "600" }}>
                        {selectedCustomer.survey &&
                        Number.isInteger(selectedCustomer.survey[10])
                          ? [
                              "0 - 1 jour",
                              "2 - 3 jours",
                              "3 - 4 jours",
                              "4 - 5 jours",
                              "+5  jours",
                            ][selectedCustomer.survey[10]]
                          : "-"}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 20,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 12,
                          fontWeight: "400",
                          color: "#7B7B7B",
                        }}
                      >
                        Fréquence souhaitée
                      </span>
                      <span style={{ fontSize: 12, fontWeight: "600" }}>
                        {selectedCustomer.survey &&
                        Number.isInteger(selectedCustomer.survey[11])
                          ? [
                              "2 - 3 jours",
                              "3 - 4 jours",
                              "4 - 5 jours",
                              "+5  jours",
                            ][selectedCustomer.survey[11]]
                          : "-"}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 20,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 12,
                          fontWeight: "400",
                          color: "#7B7B7B",
                        }}
                      >
                        Alimentation
                      </span>
                      <span style={{ fontSize: 12, fontWeight: "600" }}>
                        {selectedCustomer.survey &&
                        Number.isInteger(selectedCustomer.survey[16])
                          ? ["Équilibrée", "Junk-food", "Autres"][
                              selectedCustomer.survey[16]
                            ]
                          : "-"}
                      </span>
                    </div>
                  </>
                )}
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: "600",
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  Notes
                </span>
                <textarea
                  value={noteValue}
                  onChange={(e) => setNoteValue(e.target.value)}
                  id="story"
                  name="story"
                  rows="5"
                  style={{ outline: "none", resize: "none", padding: 6 }}
                />
                <Button
                  style={{ marginTop: 10 }}
                  onClick={() => onNoteSubmit(selectedCustomer._id)}
                >
                  Enregistrer
                </Button>
              </div>
            )}

            {value === 2 && (
              <div
                style={{
                  padding: "40px 15px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                >
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#7B7B7B",
                    }}
                  >
                    Téléphone
                  </span>
                  <span style={{ fontSize: 12, fontWeight: "600" }}>
                    {selectedCustomer?.phoneNumber}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                >
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#7B7B7B",
                    }}
                  >
                    E-mail
                  </span>
                  <span style={{ fontSize: 12, fontWeight: "600" }}>
                    {selectedCustomer?.email}
                  </span>
                </div>
              </div>
            )}
          </Box>
        </PerfectScrollbar>
        <CustomerOnboardingModal
          open={customerOnboardingModalOpen}
          handleClose={() => setCustomerOnboardingModalOpen(false)}
          customer={selectedCustomer}
        />
      </Card>
    );
  }
);

const NewTask = (props) => {
  const [taskText, setTaskText] = useState("");
  const [taskChecked, setTaskChecked] = useState(false);

  const handleAddTask = () => {
    if (taskText.trim() == "") {
    } else {
      props.uploadTask([
        ...props.tasks,
        { text: taskText, checked: taskChecked },
      ]);
      setTaskText("");
      setTaskChecked(false);
    }
  };
  const handleTaskTextChange = (event) => {
    const textLines = event.target.value.split("\n");
    if (textLines.length <= 1) {
      setTaskText(event.target.value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddTask();
    }
  };
  return (
    <FormControl className="taskList-task edit">
      <TextField
        autoFocus
        placeholder="Texte de tâche"
        className="task-input"
        value={taskText}
        onChange={handleTaskTextChange}
        onKeyDown={handleKeyPress}
        multiline
        maxRows={2}
        inputProps={{ maxLength: 85 }}
      />
      <div className="taskList-button" style={{ display: "flex" }}>
        <IconButton
          id="closeicon"
          type="button"
          aria-label="close"
          onClick={() => props.setEditTask(false)}
        >
          <CloseIcon />
        </IconButton>
        <IconButton
          id="sendicon"
          type="submit"
          aria-label="send"
          onClick={handleAddTask}
        >
          <SendIcon />
        </IconButton>
      </div>
    </FormControl>
  );
};
const TaskNote = (props) => {
  const [tasks, setTasks] = useState(null);
  const [isEditTask, setEditTask] = useState(false);
  const [visibilityTask, setVisibilityTask] = useState(true);

  const handleCheckboxChange = (index) => (event) => {
    const updatedTasks = [...tasks];
    updatedTasks[index].checked = event.target.checked;
    uploadTask(updatedTasks);
  };
  const [anchorIndex, setAnchorIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "task-popover" : undefined;

  const handlePopover = (index) => (e) => {
    e.stopPropagation();
    setAnchorIndex(index);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    setAnchorIndex(null);
  };
  const handleDelete = (index) => {
    const updatedTasks = tasks.filter((_, i) => i !== index); // Supprime l'index spécifié du tableau
    uploadTask(updatedTasks);
    setAnchorEl(null);
  };
  useEffect(() => {
    getTask();
    setEditTask(false);
  }, [props.selectedCustomer]);

  const uploadTask = async (newTasks) => {
    try {
      let response = await axios.patch(
        `https://online.korper.io/coachs/customer/${props.selectedCustomer._id}/task`,
        { task: newTasks },
        { withCredentials: true }
      );

      if (response.data.success) {
        if (response.data.task) {
          setTasks(response.data.task.list);
        } else {
          setTasks([]);
        }
      } else {
        toast.error(
          "Erreur serveur, impossible de modifier les tâche de ce client"
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "Erreur serveur, impossible de modifier les tâche de ce client"
      );
    }
  };
  const getTask = async () => {
    try {
      let response = await axios.get(
        `https://online.korper.io/coachs/customer/${props.selectedCustomer._id}/task`,
        { withCredentials: true }
      );

      if (response.data.success) {
        if (response.data.task) {
          setTasks(response.data.task.list);
        } else {
          setTasks([]);
        }
      } else {
        setLoading(false);
        toast.error(
          "Erreur serveur, impossible de recupérer les tâche de ce client"
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "Erreur serveur, impossible de recupérer les tâche de ce client"
      );
    }
  };

  const [modifyTask, setModifyTask] = useState(undefined);
  const [modifyTaskText, setModifyTaskText] = useState(undefined);
  const inputRef = useRef(null);
  const handleModify = (task) => {
    setModifyTask(task);
    setAnchorEl(null);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Enter") {
      handleSaveModifyTask(index);
      setModifyTask(undefined);
    }
  };

  const handleSaveModifyTask = (index) => {
    if (modifyTaskText.trim() == "") {
    } else {
      let newTasks = _.cloneDeep(tasks);
      newTasks[index] = { ...tasks[index], text: modifyTaskText };
      uploadTask(newTasks);
      setModifyTaskText(undefined);
    }
  };
  const handleModifyTaskText = (event) => {
    const textLines = event.target.value.split("\n");
    if (textLines.length <= 1) {
      setModifyTaskText(event.target.value);
    }
  };

  return (
    <div className="taskList">
      {tasks === null ? null : (
        <div>
          {visibilityTask &&
            tasks.map((task, index) => (
              <Tooltip
                key={index}
                title={moment(task.createdAt).format("DD/MM/YY HH:mm")}
                arrow
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -14],
                        },
                      },
                    ],
                  },
                }}
              >
                <FormControl
                  className="taskList-task"
                  style={{
                    background:
                      modifyTask?._id !== task._id ? "black" : "#565656",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  key={index}
                >
                  <IconButton
                    id="closeicon"
                    type="button"
                    aria-label="close"
                    onClick={(e) => [e.stopPropagation(), handleDelete(index)]}
                    style={{ color: "white" }}
                  >
                    <RadioButtonUncheckedIcon />
                  </IconButton>
                  <TextField
                    value={
                      modifyTask?._id !== task._id ? task.text : modifyTaskText
                    }
                    inputRef={inputRef}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onChange={handleModifyTaskText}
                    multiline
                    disabled={modifyTask?._id !== task._id}
                    maxRows={2}
                    className={
                      task.checked ? "line-through task-input" : "task-input"
                    }
                    inputProps={{ maxLength: 85 }}
                  />
                  <div className="taskList-button">
                    <IconButton
                      id="moreicon"
                      type="button"
                      aria-label="more"
                      onClick={handlePopover(index)}
                    >
                      <MoreVertIcon />
                    </IconButton>

                    <Popover
                      id={id}
                      open={open && index === anchorIndex}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Button
                          onClick={(e) => [
                            e.stopPropagation(),
                            handleModify(task),
                          ]}
                          style={{
                            padding: "8px 10px 8px 10px",
                            textTransform: "none",
                          }}
                        >
                          <Typography
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "black",
                              fontSize: "12px",
                            }}
                          >
                            <CreateIcon
                              style={{
                                marginRight: "10px",
                                color: "black",
                                alignItems: "flex-start",
                              }}
                            />
                            Modifier la tâche
                          </Typography>
                        </Button>
                        <Button
                          onClick={(e) => [
                            e.stopPropagation(),
                            handleDelete(index),
                          ]}
                          style={{
                            padding: "8px 10px 8px 10px",
                            textTransform: "none",
                          }}
                        >
                          <Typography
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "red",
                              fontSize: "12px",
                            }}
                          >
                            <DeleteForeverIcon
                              style={{ marginRight: "10px", color: "red" }}
                            />
                            Supprimer la tâche
                          </Typography>
                        </Button>
                      </div>
                    </Popover>
                  </div>
                </FormControl>
              </Tooltip>
            ))}
          {isEditTask && (
            <NewTask
              uploadTask={uploadTask}
              setEditTask={setEditTask}
              tasks={tasks}
            />
          )}
        </div>
      )}
      <div className="taskList-btn">
        <Button
          startIcon={<AddIcon />}
          size="small"
          variant="contained"
          onClick={() => setEditTask(true)}
        >
          <p style={{ textTransform: "initial" }}>Ajouter une tâche</p>
        </Button>
        {/* {visibilityTask ? (
          <VisibilityOffIcon
            style={{ fontSize: "18px" ,marginRight:"12px"}}
            variant="contained"
            onClick={() => setVisibilityTask(false)}
          />
        ) : (
          <VisibilityIcon
            style={{ fontSize: "18px" ,marginRight:"12px"}}
            variant="contained"
            onClick={() => setVisibilityTask(true)}
          />
        )} */}
      </div>
    </div>
  );
};

CustomerInfos.propTypes = {
  className: PropTypes.string,
  onCustomerSelect: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = { updateStudent };

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CustomerInfos);
